import { parseParams } from "@/select/api/base"
import { AxiosPromise, AxiosRequestConfig } from "axios"
import type { List } from "../base"
import request from "./base"

type Params = { [key: string]: string | (string | null)[] }

interface Endpoint<FormData, ResponseData> {
	list: (params?: Params, signal?: AbortSignal) => Promise<List<ResponseData>>
	get: (id: number, params?: Params) => Promise<ResponseData>
	create: (data: FormData, params?: Params) => Promise<ResponseData>
	update: (id: number, data: FormData, params?: Params) => Promise<ResponseData>
	bulkUpdate: (data: FormData[], params?: Params) => Promise<ResponseData>
	listAction: <T>(action: string, params?: Params) => Promise<T>
	action: (id: number, action: string, params?: Params) => Promise<any>
	delete: (id: number) => Promise<any>
	bulkDelete: (ids: number[]) => Promise<any>
	request: <T>(config: AxiosRequestConfig) => AxiosPromise<T>
}

export default function <FormData, ResponseData>(
	endpoint: string
): Endpoint<FormData, ResponseData> {
	return {
		list(params?: Params, signal?: AbortSignal) {
			return request.get<List<ResponseData>, List<ResponseData>>(
				endpoint + "/",
				{ params, paramsSerializer: parseParams, signal }
			)
		},
		get(id: number, params?: Params) {
			return request.get<ResponseData, ResponseData>(
				endpoint + "/" + id + "/",
				{ params }
			)
		},
		create(data: FormData, params?: Params) {
			return request.post<ResponseData, ResponseData>(endpoint + "/", data, {
				params,
			})
		},
		update(id: number, data: FormData, params?: Params) {
			return request.put<ResponseData, ResponseData>(
				endpoint + "/" + id + "/",
				data,
				{ params }
			)
		},
		bulkUpdate(data: FormData[], params?: Params) {
			return request.put<ResponseData, ResponseData>(endpoint + "/", data, {
				params,
			})
		},
		listAction<T>(action: string, params?: Params) {
			return request.get<T, T>(endpoint + "/" + action, {
				params,
				paramsSerializer: parseParams,
			})
		},
		action(id: number, action: string, params?: Params) {
			return request.get<List<any>, List<any>>(
				endpoint + "/" + id + "/" + action,
				{
					params,
				}
			)
		},
		delete(id: number) {
			return request.delete(`${endpoint}/${id}/`)
		},
		bulkDelete(data: number[]) {
			return request.delete(endpoint + "/", { data })
		},
		request<T>(config: AxiosRequestConfig) {
			return request({
				...config,
				url: `${endpoint}/${config.url}`,
			}) as AxiosPromise<T>
		},
	}
}
