import axios, { AxiosError } from "axios"
import { UserModule } from "@/store/modules/user"
import { Message } from "element-ui"
import { VNode } from "vue"

interface GeneralFailure {
	detail: string
}

export interface ValidationFailure {
	[key: string]: string[]
}

export interface ListParams {
	page: number
	page_size: number
	ordering: string
	query?: string
	status?: string[]
}

export type RouterQuery = { [key: string]: string | (string | null)[] }

export class ListQuery {
	page = 1
	page_size = 20
	ordering = "-id"
	q = ""

	constructor(routerQuery: RouterQuery) {
		if (Array.isArray(routerQuery.page)) {
			throw Error("page cannot be array")
		}
		if (Array.isArray(routerQuery.page_size)) {
			throw Error("page_size cannot be array")
		}
		if (Array.isArray(routerQuery.ordering)) {
			throw Error("ordering cannot be array")
		}
		if (Array.isArray(routerQuery.q)) {
			throw Error("q cannot be array")
		}

		if (routerQuery.page !== undefined) this.page = parseInt(routerQuery.page)
		if (routerQuery.page_size !== undefined)
			this.page_size = parseInt(routerQuery.page_size)
		if (routerQuery.ordering !== undefined) this.ordering = routerQuery.ordering
		if (routerQuery.q !== undefined) this.q = routerQuery.q
	}

	toRouterQuery(): RouterQuery {
		return {
			page: this.page.toString(),
			page_size: this.page_size.toString(),
			ordering: this.ordering,
			q: this.q,
		}
	}
}

export const defaultListQuery: ListParams = {
	page: 1,
	page_size: 20,
	ordering: "-id",
}

export type ErrorData = GeneralFailure | ValidationFailure | Error

export function toHtml(e: ErrorData): VNode {
	if ("detail" in e) return ((<span>{e.detail}</span>) as unknown) as VNode
	if (Array.isArray(e)) {
		return ((
			<ul>
				{e.map((err) => (
					<li>{toHtml(err)}</li>
				))}
			</ul>
		) as unknown) as VNode
	}
	return ((
		<ul class="message">
			{Object.entries((e as unknown) as ValidationFailure).map(
				([field, messages]) => (
					<li>
						<strong>{field}</strong>
						<ul>
							{messages.map((m) => (
								<li>{m}</li>
							))}
						</ul>
					</li>
				)
			)}
		</ul>
	) as unknown) as VNode
}

export function handleError(e: any) {
	Message({ type: "error", message: toHtml(e.response?.data) })
}

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 30000,
})

// Add token to requests if not null
service.interceptors.request.use(
	(config) => {
		if (UserModule.token) {
			if (config.headers !== undefined)
				config.headers["Authorization"] = "Bearer " + UserModule.token
		}
		return config
	},
	(error) => {
		Promise.reject(error)
	}
)

// Clean up response errors
service.interceptors.response.use(
	(cfg) => cfg.data,
	(error: AxiosError<ErrorData>): Promise<ErrorData> => {
		if (error.response) {
			return Promise.reject(error.response.data)
		}
		return Promise.reject(error.message)
	}
)

export default service
